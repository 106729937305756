import Navbar from "./Navbar";
import styled from 'styled-components';
import Typed from 'react-typed';
import { useEffect, useState } from 'react';
import StyledMain from "./app.styles";
// import ItemCard from "./ItemCard";
// import ProjectsList from "./ProjectsList";




function App() {
  const [projects, setProjects] = useState([]);
  const [category, setCategory] = useState("");
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    fetch("https://sheetdb.io/api/v1/z3o7xi7cna9ji")
    .then(res => res.json())
    .then(data => {setProjects(data)})
    .catch(err => console.log(err))
  }, [])

  return (
    <>
    <Navbar projects={projects}/>
    <StyledMain>
      <div className="main">
        <div className="hero-content">      
          <div className="hero-bottom">
            <div className="hero-bottom-top">
              <p className="hero-bottom-intro">Hi, my name is</p>
              <h1 className="name">
              Luke Sterling.
              </h1>
            </div>
              <div className="">
                  <p className="hero-bottom-typed">
                      I use
                      <Typed
                          className="md:text-6xl lg:text-5xl text-xl font-bold md:pl-2 pl-1 buzz-words"
                          strings={['Javascript', 'React']}
                          typeSpeed={100}
                          backSpeed={40}
                          loop
                      />
                      &nbsp;to build full-stack web apps.
                  </p>
                  <a className="cta-button-hero" href="#projects">
                  <button className="cta-button">My Work</button>
                  </a>
              </div>
          </div>
        </div>                       
      </div>

      {/* Projects Section */}

      {/* Mobile Button Container */}
      {/* <div className="mobile-button-container" id="projects-mobile">
        <div className="mobile-button-left">
          <button>left</button>
        </div>
        <div className="mobile-button-center">
          <h4>{category}</h4>
        </div>
        <div className="mobile-button-right">
          <button>right</button>
        </div>
      </div> */}
      {/* End Mobile Button Container */}

<div className="toggle-button-container" id="projects">
      <button className="single-button" onClick={()=>{setCategory('react website')}}>Websites</button>
      <button className="single-button" onClick={()=>{setCategory('design')}}>Logos</button>
      <button className="single-button" onClick={()=>{setCategory('')}}>All</button>
</div>
        
        {/* Card */}
      <div className="projects-section">
        <div className="tech-card-container">
        
        {projects.filter((project)=>{return project.category.includes(category)}).map((item, index)=>(
          <div key={`card-${index}`} className="card">
            <div className="crt"></div>
            <div className="card-container">
              <a href={item.link}>
                <div className="card-contents">
                  <div className="crt"></div>
                  <div className="card-header">
                    <h1>{item.name}</h1>
                  </div>
                  <div className="card-image">
                    <img src={item.picUrl} />
                  </div>
                 
                  <div className="card-body">
                    <p>{item.description}</p>
                  </div>
                  <div className="card-footer">
                    <article className="created-by">Foooooooooooooter</article>
                  </div>
                </div>
              </a>
            </div>
          </div>))}  
        
      
                      
        </div>
      </div>

      {/* End Projects Section */}


      {/* Start About Me Section */}

      <div className="about-me">
        <div className="text-wrapper">
          <h1 onClick={()=>{setIsToggled(!isToggled)}}>About Me</h1>
          <p data-toggled={isToggled}>I have 5 years of javascript and React experience. I am passionate about learning every day and staying ahead of the pack in tech. 
          </p>
          <p>I love technology, business, and psychology, and the intersection of these fields is my playground. I apply interactive human psychology to make intuitive applications which convert sales, solve problems in business, and provide an addictive user experience.</p>
        </div>
      </div>

      {/* End About Me Section */}

      {/* Start Contact Me Section */}

      

      {/* End Contact Me Section */}
      {/* Footer Start */}
      <footer className="footer-section">
        <div className="footer-container">
            <div className="footer-cta pt-5 pb-5">
                <div className="icon-container">
                    <div className="single-icon-container">
                        <div className="single-cta">
                            <i className="fas fa-map-marker-alt icon-green"></i>
                            <div className="cta-text">
                                <h4>Remote</h4>
                                <span>Based in Chicago</span>
                            </div>
                        </div>
                    </div>
                    <div className="single-icon-container">
                        <div className="single-cta">
                            <i className="fab fa-linkedin icon-green"></i>
                            <a href="https://www.linkedin.com/in/luke-sterling-a191b0247/">
                              <div className="cta-text">
                                  <h4>LinkedIn</h4>
                                  <span>Luke Sterling</span>
                              </div>
                            </a>
                        </div>
                    </div>
                    <div className="single-icon-container">
                        <div className="single-cta">
                            <i className="far fa-envelope-open icon-green"></i>
                            <div className="cta-text">
                                <h4>Email</h4>
                                <span>walker0369@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="footer-content pt-5 pb-5">
                <div className="footer-mid-container">
                    <div className="footer-widget">
                        <div className="footer-widget-heading">
                            <h3>Developers I follow :</h3>
                        </div>
                        <ul>
                            <li><a href="https://www.youtube.com/c/WesBos">Wes Bos</a></li>
                            <li><a href="https://www.youtube.com/c/WebDevSimplified">Web Dev Simplified</a></li>
                            <li><a href="https://www.youtube.com/c/DevTipsForDesigners">Dev Tips</a></li>
                            <li><a href="https://www.youtube.com/c/LevelUpTuts">Scott Tolinski</a></li>
                            <li><a href="https://www.youtube.com/c/TraversyMedia">Brad Traversy</a></li>
                            <li><a href="https://www.youtube.com/c/Fireship">Fireship.io</a></li>
                            <li><a href="https://www.youtube.com/c/CodingPhase">Coding Phase</a></li>
                            <li><a href="https://www.youtube.com/c/DesignCourse">Gary Simon</a></li>
                            <li><a href="https://www.youtube.com/kepowob">Kevin Powell</a></li>
                            <li><a href="https://www.youtube.com/c/DevEd">Dev Ed</a></li>
                        </ul>
                    </div>
                    
                    <div className="contact">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Contact Me!!!</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Want to get in touch with or hire Luke?</p>
                            </div>
                            <div className="subscribe-form">
                              <form action="https://getform.io/f/9bc3b965-2e11-4a9e-a296-7b1d0bd9203d" method="POST">
                                    <input name="email" type="text" placeholder="Email Address"/>
                                    <button><i className="fab fa-telegram-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-center text-lg-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2022, All Rights Reserved <a href="#">Luke Sterling</a></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </footer>
      {/* Footer End */}
      
      

    </StyledMain>
    </>
  );
}

export default App;

// {
    //   "status": "ok",
    //   "totalResults": 8,
    //   "articles": [
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "Laura Ly, Kristina Sgueglia, Eric Levenson",
    //   "title": "Then-officer who fatally shot Patrick Lyoya will stand trial for the killing",
    //   "description": "The White former police officer who fatally shot Patrick Lyoya, a Black man, this year in Michigan will stand trial for the killing, according to a judge's memo posted online and announced in court Monday.",
    //   "url": "https://www.cnn.com/2022/10/31/us/patrick-lyoya-shooting-christopher-schurr/index.html",
    //   "urlToImage": "https://media.cnn.com/api/v1/images/stellar/prod/221028083655-christopher-schurr-court-1027.jpg?c=16x9&q=w_800,c_fill",
    //   "publishedAt": "2022-10-31T15:59:00+00:00",
    //   "content": "The White former police officer who fatally shot Patrick Lyoya, a Black man, this year in Michigan will stand trial for the killing, according to a judges memo posted online and announced in court Mo… [+2762 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "Audrey Conklin, Rebecca Rosenberg",
    //   "title": "Delphi murders: Suspect arrested for the 2017 slayings of two teens",
    //   "description": "An Indiana man was charged for the slaying of two teens nearly five year after the young girls' bodies were found on a hiking trial in Delphi, official said.",
    //   "url": "https://www.foxnews.com/us/delphi-murders-suspect-arrested-2017-slayings-two-teens",
    //   "urlToImage": "https://static.foxnews.com/foxnews.com/content/uploads/2022/10/SPLIT.jpg",
    //   "publishedAt": "2022-10-31T15:40:39+00:00",
    //   "content": "Indiana State Police announced Monday the arrest of Richard Allen for the killings of two teenage girls nearly six years after the best friends were found dead on a hiking trail.\r\nThe lifeless bodies… [+4021 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "Swati Gupta, Hira Humayun",
    //   "title": "Collapsed India bridge that killed 134 was recently repaired, official says",
    //   "description": "The death toll has risen to 134 after a suspension bridge collapsed in India's western state of Gujarat on Sunday, as authorities revealed the structure had only recently reopened following repairs.",
    //   "url": "https://www.cnn.com/2022/10/30/asia/india-bridge-collapse-intl/index.html",
    //   "urlToImage": "https://media.cnn.com/api/v1/images/stellar/prod/221030131239-02-india-bridge-collapse-103022.jpg?c=16x9&q=w_800,c_fill",
    //   "publishedAt": "2022-10-31T15:12:00+00:00",
    //   "content": "The death toll has risen to 134 after a suspension bridge collapsed in Indias western state of Gujarat on Sunday, as authorities revealed the structure had only recently reopened following repairs.\r\n… [+2676 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "Tony Kurzweil",
    //   "title": "4 people sickened, including 1 in critical condition, in LAX hazmat incident",
    //   "description": "At least four people have been sickened, including one who is in “critical condition,” after a reported Carbon Dioxide leak at Los Angeles International Airport Monday morning. The incident was reported around 7 a.m. in the Terminal 8 baggage area, according …",
    //   "url": "https://ktla.com/news/local-news/4-people-ill-including-1-in-grave-condition-in-lax-hazmat-incident/",
    //   "urlToImage": "https://ktla.com/wp-content/uploads/sites/4/2022/10/lax-fire-truck.jpg?w=1280",
    //   "publishedAt": "2022-10-31T15:09:47+00:00",
    //   "content": "At least four people have been sickened, including one who is in “critical condition,” after a reported Carbon Dioxide leak at Los Angeles International Airport Monday morning. \r\nThe incident was rep… [+1377 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "John Wagner, Mariana Alfaro",
    //   "title": "Midterm elections latest news: With Election Day looming, Obama is back on the trail for Democrats",
    //   "description": "The Nov. 8 general election is just over a week away. Follow our live coverage to get the latest news on the 2022 midterm elections.",
    //   "url": "https://www.washingtonpost.com/politics/2022/10/31/election-2022-latest-news/",
    //   "urlToImage": "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/EOIYZ4NUSNAHNPZFHM5ZNLFGFE.JPG&w=1440",
    //   "publishedAt": "2022-10-31T13:57:00+00:00",
    //   "content": "Georgia Gov. Brian Kemp and Democratic rival Stacey Abrams focused on abortion and crime in their final gubernatorial debate Sunday in a showdown that produced few fireworks but showed the stark diff… [+582 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": null,
    //   "title": "Grain ships sail despite Moscow's pullout from deal; missiles rain on Ukraine",
    //   "description": "Ships brought grain from Ukrainian ports on Monday, suggesting Moscow had stopped short of reimposing a blockade that might have caused world hunger, despite suspending its participation in a U.N. programme to safely export grain from the war zone.",
    //   "url": "https://www.reuters.com/world/europe/un-turkey-ukraine-press-ahead-with-black-sea-grain-deal-despite-russian-pullout-2022-10-31/",
    //   "urlToImage": "https://www.reuters.com/resizer/2KpOIoLRcVDc4u8sq1EIJ4LEePo=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/4DA5B2H4KFPSTBWSD572YBFZ6A.jpg",
    //   "publishedAt": "2022-10-31T13:56:00+00:00",
    //   "content": "KYIV, Oct 31 (Reuters) - Ships brought grain from Ukrainian ports on Monday, suggesting Moscow had stopped short of reimposing a blockade that might have caused world hunger, despite suspending its p… [+5683 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "Kelly Kasulis Cho",
    //   "title": "Survivor’s injuries in Seoul show how crowd crushes can kill",
    //   "description": "Juliana Velandia Santaella was trapped in an Itaewon alley, with only her head above piles of people. She felt like her lungs were being flattened.",
    //   "url": "https://www.washingtonpost.com/world/2022/10/31/seoul-halloween-crush-mexico-survivor-itaewon/",
    //   "urlToImage": "https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/CILDQBIMPZG4I562VAYOZHM74I.JPG&w=1440",
    //   "publishedAt": "2022-10-31T11:45:00+00:00",
    //   "content": "Comment on this story\r\nJuliana Velandia Santaella took a photo of young women dressed up as bananas, a hot dog and french fries on the streets of Itaewon at 10:08 p.m. Saturday. Then she decided to g… [+4067 chars]"
    //   },
    //   {
    //   "source": {
    //   "id": "google-news",
    //   "name": "Google News"
    //   },
    //   "author": "Karen Freifeld and Luc Cohen",
    //   "title": "Trump's company 'cheated' tax authorities, prosecutor says at trial",
    //   "description": "NEW YORK (Reuters) -Former President Donald Trump's real estate company cheated tax authorities over a 15-year period, a New York prosecutor told a jury on...",
    //   "url": "https://news.yahoo.com/opening-statements-criminal-case-against-100443801.html",
    //   "urlToImage": "https://media.zenfs.com/en/reuters.com/0620531ba8406146feded60573aa750f",
    //   "publishedAt": "2022-10-31T10:04:43+00:00",
    //   "content": "By Karen Freifeld and Luc Cohen\r\nNEW YORK (Reuters) -Former President Donald Trump's real estate company cheated tax authorities over a 15-year period, a New York prosecutor told a jury on Monday in … [+4209 chars]"
    //   }
    //   ]
    //   }